<template>
  <v-main class="reset-password-request-content">
    <v-card
      color="#404040"
      elevation="20"
      width="380"
      class="mx-auto my-auto">
      <v-card-title class="reset-password-request-card-title justify-center white--text">
        <h2>{{ $t('applications.base.resetPasswordRequestForm.forgottenPassword') }}</h2>
      </v-card-title>
      <v-divider class="mt-2 mb-4"/>
      <v-card-text
        v-if="!requestSuccessful"
        v-on:keyup.enter="sendPasswordResetRequest()">
        <v-form v-model="isFormValid" @submit.prevent>
          <v-text-field
            v-model="form.email"
            solo
            clearable
            prepend-inner-icon="mdi-email"
            class="reset-password-request-input"
            type="email"
            maxlength="254"
            autofocus
            :error="!!errorMessage"
            :error-messages="errorMessage"
            :label="$t('common.labels.email')"
            :rules="[
              formRules.email,
              formRules.required
            ]">
            <template v-slot:append>
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" color="grey" class="info-icon">
                    mdi-information
                  </v-icon>
                </template>
                {{ $t('applications.base.resetPasswordRequestForm.infoTooltip') }}
              </v-tooltip>
            </template>
          </v-text-field>
        </v-form>
        <v-btn
          block
          rounded
          :depressed="isFormValid ? false : true"
          :color="isFormValid ? 'green' : 'grey'"
          :class="{
            'disable-events': !isFormValid || loadingRequest,
            'white--text': isFormValid,
          }"
          @click="sendPasswordResetRequest()">
          {{ $t('applications.base.resetPasswordRequestForm.sendButton') }}
        </v-btn>
      </v-card-text>
      <v-card-text v-else class="white--text" style="opacity: 0.6;">
        <div class="d-flex justify-center mb-2">
          <video width="150" height="150" autoplay muted>
            <source :src="require('@/assets/videos/check_animation.webm')" type="video/webm">
          </video>
        </div>
        <div class="d-flex justify-center white--text mb-3">
          <h2>
            {{ $t('applications.base.resetPasswordRequestForm.requestReceived') }}
          </h2>
        </div>
        <h3 class="white--text">
          {{ $t('applications.base.resetPasswordRequestForm.confirmationText') }}
        </h3>
      </v-card-text>
      <v-divider class="my-2"/>
      <v-btn
        tile
        block
        text
        color="grey"
        class="white--text overline"
        :to="{ name: 'LoginForm' }">
        {{ $t('applications.base.resetPasswordRequestForm.backToLoginButton') }}
      </v-btn>
      <div class="h-poweredby-bar mt-4 justify-center align-center">
          <span class="overline white--text">Powered by</span>
          <a href="https://www.hubbiz.be" target="_blank">
            <v-img
              src="@/assets/images/hubbiz_full_mix_white_h.png"
              contain
              max-height="20"
              max-width="100"/>
          </a>
      </div>
    </v-card>
  </v-main>
</template>

<script>
export default {
  name: 'ResetPasswordRequestForm',
  data () {
    return {
      lastLoggedInUser: JSON.parse(localStorage.getItem('hubbiz-last-logged')),
      loadingRequest: false,
      isFormValid: false,
      requestSuccessful: false,
      errorMessage: '',
      form: {
        email: '',
      },
      formRules: {
        required: value => !!value || `${this.$t('common.labels.required')}.`,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || `${this.$t('common.errors.invalidEmail')}.`
        },
      },
    }
  },
  methods: {
    sendPasswordResetRequest () {
      this.loadingRequest = true
      this.axios
        .post('request-password-reset', { email: this.form.email })
        .then(response => {
          if (response.status === 204) {
            this.requestSuccessful = true
          }
        })
        .catch(error => {
          this.errorMessage = error.response?.data.detail
        })
        .finally(() => {
          this.loadingRequest = false
        })
    },
  },
}
</script>

<style>
.v-main.reset-password-request-content >
  .v-main__wrap {
    display: flex;
    background-image: url('@/assets/images/hubbiz_login_wallpaper.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: lighten;
    background-color: rgba(255, 255, 255, 0.1);
  }
.v-input.reset-password-request-input >
  .v-input__control >
    .v-input__slot {
      opacity: 0.5;
    }
.v-input.reset-password-request-input >
  .v-input__control >
    .v-input__slot >
      .v-text-field__slot {
        margin-left: 15px;
        margin-right: 15px;
      }
</style>
<style scoped>
.v-card__title.reset-password-request-card-title {
  opacity: 0.6;
  text-align: center;
  word-break: normal;
}
.info-icon {
  cursor: help;
}
.disable-events {
  pointer-events: none;
}
div.v-tooltip__content {
  max-width: 300px;
}
div.h-poweredby-bar {
  display: flex;
  background-color: rgba(0, 0, 0, 0.2)!important;
}
div.h-poweredby-bar span {
  opacity: 0.6;
}
</style>
