export default {
  all: 'All',
  selectionOnly: 'Selected Only',
  loading: 'Loading',
  showing: 'Showing',
  of: 'of',
  for: 'for',
  applicationNames: {
    base: 'Settings',
    contact: 'Contacts',
    helpdesk: 'Helpdesk',
    inventory: 'Inventory',
    timesheets: 'Timesheets',
  },
  applications: {
    base: {
      changeUserPasswordDialog: {
        changePasswordOf: 'Change Password of',
      },
      loginForm: {
        forgottenPassword: 'I forgot my password',
        login: 'Login',
        loginBtnText: 'Login',
        switchBtnText: 'Switch user',
        welcomeBack: 'Welcome back,',
      },
      menus: {
        logout: 'Log out',
        myProfile: 'My Profile',
      },
      resetPasswordForm: {
        backToLoginButton: 'Back to Login',
        requestSuccess: 'Password successfully changed',
        resetPassword: 'Reset Password',
        sendButton: 'Send',
      },
      resetPasswordRequestForm: {
        backToLoginButton: 'Back to Login',
        confirmationText: 'If the provided email address was valid, you will shortly receive an email to explain you the steps to reset your password.',
        forgottenPassword: 'Forgotten Password',
        infoTooltip: 'Enter the email address corresponding to your account and we will send you a link to reset your password.',
        requestReceived: 'We received your request',
        sendButton: 'Send',
      },
    },
    contact: {
      address: {
        messages: {
          addressCreated: 'Address created',
          addressUpdated: 'Address updated',
          addressDeleted: 'Address deleted',
        },
      },
      buttons: {
        archiveContact: 'Archive Contact',
        unarchiveContact: 'Unarchive Contact',
      },
      company: {
        messages: {
          companyCreated: 'Company \'{name}\' created',
          companyUpdated: 'Company updated',
          companyDeleted: 'Company \'{name}\' deleted',
        },
      },
      userFromContactFormDialog: {
        userTypeFormHint: {
          system: 'This type of user will have access to the default platform.',
          portal: 'This type of user will only have access to the portal, if enabled.',
          portalRef: 'This type of user will only have access to the portal, if enabled, but with company wide view.',
        }
      },
    },
    helpdesk: {

    },
    inventory: {

    },
    timesheets: {

    },
  },
  common: {
    buttons: {
      addAddress: 'Add Address',
      cancel: 'Cancel',
      changePassword: 'Change password',
      delete: 'Delete',
      edit: 'Edit',
      loadMore: 'Load more',
      save: 'Save',
      export: 'Export',
      create: 'Create',
      createUser: 'Create User',
      open: 'Open',
      archive: 'Archive',
      unarchive: 'Unarchive',
    },
    errors: {
      invalidEmail: 'Adresse Email Invalide',
      invalidPostalCode: 'Invalid Postal Code',
      passwordsDoNotMatch: 'Passwords do not match',
    },
    hints: {
      pressToValidate: 'Press <enter>, <tab>, <space> or <,> to validate new entry',
    },
    labels: {
      archived: 'Archived',
      unarchived: 'Not Archived',
      slaLevel: 'SLA Level',
      domain: 'Domaine',
      domains: 'Domains',
      contacts: 'Contacts',
      company: 'Company',
      companies: 'Companies',
      selection: 'Selection',
      duration: 'Duration',
      actions: 'Actions',
      createdBy: 'Created By',
      source: 'Source',
      description: 'Description',
      author: 'Author',
      assignee: 'Assignee',
      unassigned: 'Unassigned',
      customer: 'Customer',
      from: 'From',
      to: 'To',
      name: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      language: 'Language',
      phone: 'Phone',
      phonePerso: 'Personal Phone',
      phonePersoMobile: 'Personal Phone (mobile)',
      phonePro: 'Professional Phone',
      phoneProMobile: 'Professional Phone (mobile)',
      address: 'Address',
      addresses: 'Addresses',
      streetPerso: 'Address',
      zipCodePerso: 'Zip/Postal Code',
      city: 'City',
      country: 'Country',
      username: 'Username',
      password: 'Password',
      newPassword: 'New Password',
      newPasswordConfirm: 'Confirm New Password',
      required: 'Required',
      userType: 'User Type',
      user: 'User',
      status: 'Status',
    },
  },
  timing: {
    minutes: 'minute | minute | minutes',
    hours: 'hour | hour | hours',
    days: 'day | day | days',
  },
}
