<template>
  <v-card tile elevation="3">
    <v-toolbar
      flat
      dense
      v-if="
        contact
        && !editing
        && (
          hasPermission('add_contactcontact')
          || hasPermission('change_contactcontact')
          || hasPermission('delete_contactcontact')
          || hasPermission('add_user')
        )
      ">
      <v-hover
        v-slot="{ hover }"
        v-if="contact">
        <v-btn
          text
          :color="archiveButtonOptions(hover).color"
          :class="{'disable-events': !!contact.user || !hasPermission('change_contactcontact')}"
          @click="contactArchiving()">
          <v-icon left>{{ archiveButtonOptions(hover).icon }}</v-icon>
          {{ archiveButtonOptions(hover).text }}
        </v-btn>
      </v-hover>
      <v-divider v-if="contact" vertical/>
      <v-btn
        text
        v-if="
          !contact.is_archived
          && ![1, 2].includes(contact.id)
          && hasPermission('change_contactcontact')"
        @click="edit()">
        <v-icon small left>mdi-pencil</v-icon>
        {{ $t('common.buttons.edit') }}
      </v-btn>
      <v-btn
        text
        v-if="!contact.user && !contact.is_archived && hasPermission('add_user')"
        @click="createUserForContact(contact)">
        <v-icon small left>mdi-account-plus</v-icon>
        {{ $t('common.buttons.createUser') }}
      </v-btn>
      <v-btn
        text
        v-if="!contact.user && hasPermission('delete_contactcontact')"
        @click="deleteContact(contact)">
        <v-icon small left>mdi-delete</v-icon>
        {{ $t('common.buttons.delete') }}
      </v-btn>
      <v-btn
        text
        v-if="hasPermission('add_contactcontact')"
        :to="{ name: 'ContactContactFormCreate' }">
        <v-icon small left>mdi-plus</v-icon>
        {{ $t('common.buttons.create') }}
      </v-btn>
      <template
        v-if="
          contact
          && ![1, 2].includes(contact.id)
          && contact.user?.id
          && hasPermission('view_user')
        ">
        <v-spacer/>
        <v-divider vertical/>
        <v-btn
          text
          :color="contact.user.user_type === 'system' ? 'green' : 'primary'"
          :to="{ name: 'UserFormRead', params: { userId: contact.user.id } }">
          <v-icon small left>mdi-account</v-icon>
          User ({{ contact.user.user_type_display }})
        </v-btn>
      </template>
    </v-toolbar>
    <v-divider v-if="contact"/>
    <template v-if="!loadingContact">
      <v-alert
        v-if="contact && contact.user && editing"
        type="info"
        dense
        text
        outlined
        icon="mdi-account-sync"
        class="ma-4 mb-0">
        Changes on some fields will be synchronized with linked User
      </v-alert>
      <v-form
        class="pa-4"
        v-model="isFormValid"
        :disabled="!editing">
        <v-row class="my-0">
          <v-col>
            <v-text-field
              v-model="form.first_name"
              label="First Name"
              required
              clearable
              type="text"
              maxlength="150"
              :counter="editing"
              :rules="[formRules.required]"/>
          </v-col>
          <v-col>
            <v-text-field
              v-model="form.last_name"
              label="Last Name"
              required
              clearable
              type="text"
              maxlength="150"
              :counter="editing"
              :rules="[formRules.required]"/>
          </v-col>
        </v-row>
        <v-textarea
          v-model="form.note"
          label="Note"
          clearable
          type="text"
          rows="3"
          auto-grow
          maxlength="500"
          :counter="editing"/>
        <v-autocomplete
          v-model="form.tags"
          label="Tags"
          multiple
          hide-selected
          clearable
          :loading="loadingTagsInput"
          :items="tags"
          item-text="name"
          return-object
          @click="getTagsInput">
          <template v-slot:item="data">
            <v-chip
              small
              class="font-weight-bold"
              :class="{ 'white--text': shouldTextBeWhite(data.item.color) }"
              :color="data.item.color">
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:selection="data">
            <v-chip
              small
              class="font-weight-bold"
              :color="data.item.color"
              :class="{ 'white--text': shouldTextBeWhite(data.item.color) }"
              :close="editing"
              @click:close="removeTagFromSelection(data.item)">
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
        <v-tabs background-color="grey lighten-4">
          <v-tab>
            <v-icon left>mdi-office-building</v-icon>
            Professional
          </v-tab>
          <v-tab>
            <v-icon left>mdi-home</v-icon>
            Personal
          </v-tab>
          <v-tab-item>
            <v-row class="my-0">
              <v-col>
                <v-text-field
                  v-model="form.email"
                  label="Professional Email"
                  required
                  clearable
                  type="email"
                  maxlength="254"
                  :counter="editing"
                  :rules="[formRules.required]"/>
                <v-text-field
                  v-model="form.phone_pro"
                  label="Professional Phone"
                  clearable
                  type="tel"
                  maxlength="100"
                  :counter="editing"/>
                <v-text-field
                  v-model="form.phone_pro_mobile"
                  label="Professional Phone (mobile)"
                  clearable
                  type="tel"
                  maxlength="100"
                  :counter="editing"/>
              </v-col>
              <v-col>
                <v-autocomplete
                  v-model="form.company"
                  label="Company"
                  clearable
                  item-text="name"
                  return-object
                  :disabled="!editing && contactId && !!contact?.company"
                  :loading="loadingCompaniesInput"
                  :items="companies"
                  @click="getCompaniesInput"
                  @change="changeCompanySelection()"/>
                <v-select
                  v-if="form.company"
                  v-model="form.company_address"
                  label="Company Address"
                  :loading="loadingCompanyAddressesInput"
                  :menu-props="{ top: false, offsetY: true }"
                  :items="companyAddresses"
                  item-value="id"
                  return-object
                  clearable
                  @click="getCompanyAddressesInput(form.company)">
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-on="on" v-bind="attrs" two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.string_repr }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="data">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.string_repr }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row class="my-0">
              <v-col cols=12 sm=6>
                <v-text-field
                  v-model="form.phone_perso"
                  label="Personal Phone"
                  clearable
                  type="tel"
                  maxlength="100"
                  :counter="editing"/>
                <v-text-field
                  v-model="form.phone_perso_mobile"
                  label="Personal Phone (mobile)"
                  clearable
                  type="tel"
                  maxlength="100"
                  :counter="editing"/>
              </v-col>
              <v-col cols=12 sm=6>
                <v-text-field
                  v-model="form.street_perso"
                  label="Address"
                  required
                  clearable
                  autofocus
                  type="text"
                  maxlength="255"
                  :counter="editing"/>
                <v-text-field
                  v-model="form.zip_code_perso"
                  label="Zip/Postal Code"
                  required
                  clearable
                  type="text"
                  maxlength="10"
                  :counter="editing"
                  :rules="[formRules.zipCode]"/>
                <v-text-field
                  v-model="form.city_perso"
                  label="City"
                  required
                  clearable
                  type="text"
                  maxlength="100"
                  :counter="editing"/>
                <v-text-field
                  v-model="form.country_perso"
                  label="Country"
                  required
                  clearable
                  type="text"
                  maxlength="100"
                  :counter="editing"/>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-form>
      <template v-if="editing">
        <v-divider/>
        <v-card-actions>
          <v-btn
            text
            @click="cancel()">
            Cancel
          </v-btn>
          <v-btn
            text
            color="green"
            :disabled="!isFormValid"
            v-if="hasPermission('change_contactcontact') || hasPermission('add_contactcontact')"
            @click="!contact ? createContact() : updateContact(contact)">
            Save
          </v-btn>
        </v-card-actions>
      </template>
    </template>
    <template v-else>
      <v-col cols=12 align="center" justify="center">
        <h-request-loading/>
      </v-col>
    </template>
  </v-card>
</template>

<script>
import HRequestLoading from '@/common/components/HRequestLoading'
import DeleteModal from '@/common/components/DeleteModal'
import WarningModal from '@/common/components/WarningModal'
import UserFromContactFormDialog from './UserFromContactFormDialog'
import ContactCompanyService from '@/services/contact/contact.company.service'
import ContactContactService from '@/services/contact/contact.contact.service'
import ContactContactTagService from '@/services/contact/contact.contact.tag.service'
export default {
  name: 'ContactContactForm',
  components: {
    HRequestLoading
  },
  props: {
    editing: { type: Boolean },
    contactId: { type: [String, Number], default: () => { return null } }
  },
  data () {
    return {
      loadingCompaniesInput: false,
      loadingTagsInput: false,
      loadingCompanyAddressesInput: false,
      loadingContact: false,
      contact: null,
      tags: [],
      companies: [],
      companyAddresses: [],
      form: {
        first_name: '',
        last_name: '',
        note: '',
        tags: [],
        company: null,
        company_address: null,
        email: '',
        phone_pro: '',
        phone_pro_mobile: '',
        phone_perso: '',
        phone_perso_mobile: '',
        street_perso: '',
        zip_code_perso: '',
        city_perso: '',
        country_perso: ''
      },
      isFormValid: false,
      formRules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !value || pattern.test(value) || 'Invalid e-mail.'
        },
        zipCode: value => {
          const pattern = /^([0-9]{3,10})$/
          return !value || pattern.test(value) || 'Invalid Postal Code.'
        }
      }
    }
  },
  async mounted () {
    if (this.contactId) await this.getContact(this.contactId)
    if (this.contact) this.initFormFields()
  },
  methods: {
    archiveButtonOptions (hover) {
      if (this.contact.is_archived && hover) {
        return {
          icon: 'mdi-archive-arrow-up',
          text: 'Unarchive',
          color: 'green',
        }
      }
      if (this.contact.is_archived && !hover) {
        return {
          icon: 'mdi-archive',
          text: 'Archived',
          color: 'red',
        }
      }
      if (!this.contact.is_archived && hover) {
        return {
          icon: 'mdi-archive-arrow-down',
          text: 'Archive',
          color: 'red',
        }
      }
      if (!this.contact.is_archived && !hover) {
        return {
          icon: 'mdi-archive-outline',
          text: 'Not Archived',
          color: 'green',
        }
      }
    },
    contactArchiving () {
      this.contact.is_archived
      ? this.unarchiveContact(this.contact)
      : this.archiveContact(this.contact)
    },
    async unarchiveContact (contact) {
      const unarchivedContact = await ContactContactService.unarchiveContact(contact.id)
      if (unarchivedContact) {
        this.contact = unarchivedContact
        this.$dialog.notify.success(
          `Contact '${contact.full_name}' unarchived`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async archiveContact (contact) {
      const archivedContact = await ContactContactService.archiveContact(contact.id)
      if (archivedContact) {
        this.contact = archivedContact
        this.$dialog.notify.success(
          `Contact '${contact.full_name}' archived`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000,
          }
        )
      }
    },
    async getContact (contactId) {
      this.loadingContact = true
      this.contact = await ContactContactService.getContact(contactId)
      this.loadingContact = false
    },
    async getCompaniesInput () {
      this.loadingCompaniesInput = true
      this.companies = await ContactCompanyService.getCompaniesInput()
      this.loadingCompaniesInput = false
    },
    async getTagsInput () {
      this.loadingTagsInput = true
      this.tags = await ContactContactTagService.getTagsInput()
      this.loadingTagsInput = false
    },
    async getCompanyAddressesInput (company) {
      this.loadingCompanyAddressesInput = true
      this.companyAddresses = await ContactCompanyService.getCompanyAddressesInput(company.id)
      this.loadingCompanyAddressesInput = false
    },
    changeCompanySelection () {
      this.form.company_address = null
    },
    initFormFields () {
      for (const field in this.form) {
        if (field === 'tags') this.tags = this.contact[field]
        if (field === 'company') this.companies = [this.contact[field]]
        if (field === 'company_address') this.companyAddresses = [this.contact[field]]
        this.form[field] = this.contact[field]
      }
    },
    edit () {
      this.$emit('toggleEdit', true)
    },
    cancelEdit () {
      this.$emit('toggleEdit', false)
    },
    cancel () {
      this.cancelEdit()
      if (this.contact) {
        this.initFormFields()
      } else {
        history.back()
      }
    },
    async checkUserAndContactDifferencies (user) {
      let proceed = true
      if (
        user &&
        (
          this.form.first_name !== user.first_name ||
          this.form.last_name !== user.last_name ||
          this.form.email !== user.email
        )
      ) {
        proceed = await this.$dialog.show(WarningModal, {
          icon: 'account-switch',
          title: 'Differences between User and Contact',
          message: "Some fields may differ between Contact and linked User ('First Name', 'Last Name' and/or 'Email')",
          advice: 'Please check your form data.',
          checkRequired: true,
          checkMessage: "I'm aware that these user fields will be overridden",
          okText: 'PROCEED',
          okColor: 'green',
          okHandler: function () {
            return new Promise(resolve => {
              resolve()
            })
          },
          noButton: true,
          noText: 'CANCEL',
          persistent: true,
          waitForResult: true
        })
      }
      return proceed
    },
    async createContact () {
      const {
        first_name,
        last_name,
        note,
        tags,
        email,
        phone_pro,
        phone_pro_mobile,
        phone_perso,
        phone_perso_mobile,
        street_perso,
        zip_code_perso,
        city_perso,
        country_perso,
        company,
        company_address
      } = this.$data.form
      const createdContact = await ContactContactService.createContact({
        first_name: first_name,
        last_name: last_name,
        note: note || '',
        tag_ids: tags ? tags.map(tag => tag.id) : [],
        email: email || '',
        phone_pro: phone_pro || '',
        phone_pro_mobile: phone_pro_mobile || '',
        phone_perso: phone_perso || '',
        phone_perso_mobile: phone_perso_mobile || '',
        street_perso: street_perso || '',
        zip_code_perso: zip_code_perso || '',
        city_perso: city_perso || '',
        country_perso: country_perso || '',
        company_id: company ? company.id : null,
        company_address_id: company_address ? company_address.id : null
      })
      if (createdContact) {
        this.cancelEdit()
        this.$router.push({ name: 'ContactContactFormRead', params: { contactId: createdContact.id } })
        this.$dialog.notify.success(
          `Contact '${createdContact.full_name}' created`, {
            position: 'top-right',
            border: 'left',
            dense: true,
            timeout: 5000
          }
        )
      }
    },
    async updateContact (contact) {
      const {
        first_name,
        last_name,
        note,
        tags,
        email,
        phone_pro,
        phone_pro_mobile,
        phone_perso,
        phone_perso_mobile,
        street_perso,
        zip_code_perso,
        city_perso,
        country_perso,
        company,
        company_address
      } = this.$data.form
      let proceed = await this.checkUserAndContactDifferencies(contact.user)
      if (proceed) {
        if (!this.contact.company && company) {
          proceed = await this.$dialog.show(WarningModal, {
            title: 'Warning: setting Company',
            message: `You are trying to set a Company (${company.name}) for this Contact.`,
            advice: 'Records related to this contact will also be linked to this Company.',
            checkRequired: true,
            checkAlert: 'Keep in mind that this change is irreversible, for data consistency.',
            checkMessage: "I understood",
            okText: 'PROCEED',
            okColor: 'green',
            okHandler: function () {
              return new Promise(resolve => {
                resolve()
              })
            },
            noButton: true,
            noText: 'CANCEL',
            persistent: true,
            waitForResult: true
          })
        }
        if (proceed) {
          const updatedContact = await ContactContactService.updateContact(contact.id, {
            first_name: first_name,
            last_name: last_name,
            note: note || '',
            tag_ids: tags ? tags.map(tag => tag.id) : [],
            email: email || '',
            phone_pro: phone_pro || '',
            phone_pro_mobile: phone_pro_mobile || '',
            phone_perso: phone_perso || '',
            phone_perso_mobile: phone_perso_mobile || '',
            street_perso: street_perso || '',
            zip_code_perso: zip_code_perso || '',
            city_perso: city_perso || '',
            country_perso: country_perso || '',
            company_id: company ? company.id : null,
            company_address_id: company_address ? company_address.id : null
          })
          if (updatedContact) {
            this.cancelEdit()
            this.contact = updatedContact
            this.$dialog.notify.success(
              'Contact updated', {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }
    },
    createUserForContact (contact) {
      this.$dialog.show(UserFromContactFormDialog, {
        persistent: true,
        waitForResult: true,
        contact: contact
      }).then(async result => {
        if (result) {
          const returnedContact = await ContactContactService.createUserForContact(contact.id, {
            username: result.username,
            password: result.password,
            user_type: result.user_type,
          })
          if (returnedContact) {
            this.contact = returnedContact
            this.$dialog.notify.success(
              `User created for contact '${returnedContact.full_name}'`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    deleteContact (contact) {
      this.$dialog.show(DeleteModal, {
        alertMessage: 'By deleting a contact, you may loose links and history records.',
        checkRequired: true,
        checkMessage: 'I prefer deleting this contact',
        recordName: 'Contact',
        recordNamePlural: 'Contacts',
        persistent: true,
        waitForResult: true
      }).then(async yes => {
        if (yes) {
          const response = ContactContactService.deleteContacts({ ids: [contact.id] })
          if (response) {
            this.$dialog.notify.success(
              `Contact '${contact.full_name}' deleted`, {
                position: 'top-right',
                border: 'left',
                dense: true,
                timeout: 5000
              }
            )
            this.$router.push({ name: 'ContactContactListing' })
          }
        }
      }).catch(error => {
        // TODO: Do something smarter than just printing the error to console, which shouldn't be done
        console.log(error)
      })
    },
    removeTagFromSelection (tag) {
      this.form.tags = this.form.tags.filter(item => item.id !== tag.id)
    }
  }
}
</script>

<style scoped>
  .disable-events {
    pointer-events: none;
  }
  div.v-toolbar__content .v-btn {
    border-radius: 0;
    height: 100%!important;
    max-height: none;
  }
  div.v-select.v-input--is-disabled .v-select__selections .v-list-item__content {
    opacity: 0.38;
  }
</style>
